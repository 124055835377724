import * as Models from '../models/question';
import * as ApiModels from '../api-models/question';
import { withApiMetadata, withModelMetadata } from './utils';

export const answerDefinitionModelToApi = (definition: Models.AnswerDefinition): ApiModels.AnswerDefinition => withApiMetadata(definition);

export const answerDefinitionInputApiToModel = (input: ApiModels.AnswerDefinitionInput): Models.AnswerDefinitionInput => input;

export const answerDefinitionApiToModel = (definition: ApiModels.AnswerDefinition): Models.AnswerDefinition => withModelMetadata(definition);


export const questionDefinitionModelToApi = (question: Models.QuestionDefinition): ApiModels.QuestionDefinition => withApiMetadata(question);

export const questionDefinitionInputApiToModel = (input: ApiModels.QuestionDefinitionInput): Models.QuestionDefinitionInput => ({
    ...input,
    answerDefinitions: input.answerDefinitions.map(answerDefinitionInputApiToModel),
});
export const questionDefinitionInputModelToApi = (input: Models.QuestionDefinitionInput): ApiModels.QuestionDefinitionInput => ({
    ...input,
    answerDefinitions: input.answerDefinitions.map(answerDefinitionInputApiToModel),
});

export const questionDefinitionPatchApiToModel = (patch: ApiModels.QuestionDefinitionPatch): Models.QuestionDefinitionPatch => patch;

export const questionDefinitionApiToModel = (question: ApiModels.QuestionDefinition): Models.QuestionDefinition => withModelMetadata(question);

export const enrichedQuestionDefinitionApiToModel = (question: ApiModels.EnrichedQuestionDefinition): Models.EnrichedQuestionDefinition => ({
    // TODO any
    ...withModelMetadata<any>(question),
    answerDefinitions: question.answerDefinitions.map(answerDefinitionApiToModel),
});

export const enrichedQuestionDefinitionModelToApi = (question: Models.EnrichedQuestionDefinition): ApiModels.EnrichedQuestionDefinition => ({
    ...question,
    ...questionDefinitionModelToApi(question),
    answerDefinitions: question.answerDefinitions.map(answerDefinitionModelToApi),
});

export const questionDefinitionPatchModelToApi = (patch: Models.QuestionDefinitionPatch): ApiModels.QuestionDefinitionPatch => patch;

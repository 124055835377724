import { WithApiMetadata, BaseMetadataObject, DeletedAtMetadataObject } from '../utils';

const isDeletedAtMetadataObject = (obj: BaseMetadataObject): obj is DeletedAtMetadataObject => ('deletedAt' in obj && !!obj.deletedAt);

export function withApiMetadata<T extends BaseMetadataObject>(obj: T): WithApiMetadata<T> {
    const base = {
        ...obj,
        createdAt: obj.createdAt.toISOString(),
        updatedAt: obj.updatedAt.toISOString(),
    };
    return isDeletedAtMetadataObject(obj) ? {
        ...base,
        deletedAt: obj.deletedAt.toISOString()
    } as any: base;
}

export function withModelMetadata<T extends BaseMetadataObject>(obj: WithApiMetadata<T>): T {
    const base = {
        ...obj,
        createdAt: new Date(obj.createdAt),
        updatedAt: new Date(obj.updatedAt),
    };
    return ('deletedAt' in obj && !!obj.deletedAt) ? {
        ...base,
        deletedAt: new Date(obj.deletedAt as number)
    } as any: base;
}

import * as Models from '../models/data';
import * as ApiModels from '../api-models/data';
import { withApiMetadata, withModelMetadata } from './utils';

export const answerDataFilterApiToModel = (apiFilter: ApiModels.AnswerDataFilter): Models.AnswerDataFilter => {
    const filter: Models.AnswerDataFilter = {};
    if (apiFilter.questionDefinitionIds) {
        filter.questionDefinitionIds = apiFilter.questionDefinitionIds;
    }
    if (apiFilter.startDate) {
        filter.startDate = new Date(apiFilter.startDate);
    }
    if (apiFilter.endDate) {
        filter.endDate = new Date(apiFilter.endDate);
    }
    return filter;
};

export const answerDataFilterModelToApi = (apiFilter: Models.AnswerDataFilter): ApiModels.AnswerDataFilter => {
    const filter: ApiModels.AnswerDataFilter = {};
    if (apiFilter.questionDefinitionIds) {
        filter.questionDefinitionIds = apiFilter.questionDefinitionIds;
    }
    if (apiFilter.startDate) {
        filter.startDate = apiFilter.startDate.toISOString();
    }
    if (apiFilter.endDate) {
        filter.endDate = apiFilter.endDate.toISOString();
    }
    return filter;
};


export const answerDatumModelToApi = (datum: Models.AnswerDatum): ApiModels.AnswerDatum => withApiMetadata(datum);

export const answerDatumApiToModel = (datum: ApiModels.AnswerDatum): Models.AnswerDatum => withModelMetadata(datum);

import 'twin.macro';
import 'styled-components/macro';

import { QuestionsGrid } from './questions/QuestionsGrid';

function Definitions() {
    return <QuestionsGrid />;
}

export default Definitions;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { RecorderProvider } from './api/RecorderContext';
import ErrorBoundary from './shared/ErrorBoundary';
import eruda from 'eruda';

eruda.init();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <ErrorBoundary>
            <BrowserRouter>
                <RecorderProvider>
                    <App />
                </RecorderProvider>
            </BrowserRouter>
        </ErrorBoundary>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import * as ApiModels from '../api-models/user';
import * as Models from '../models/user';
import { withApiMetadata, withModelMetadata } from './utils';

export const userModelToApi = (user: Models.User): ApiModels.User => {
    const r = withApiMetadata(user);
    if ('token' in r) { delete r.token; }
    return r;
};

export const enrichedUserModelToApi = (user: Models.EnrichedUser): ApiModels.EnrichedUser => ({
    ...userModelToApi(user),
    token: user.token,
});


export const userInputApiToModel = (user: ApiModels.UserInput): Models.UserInput =>user;

export const userApiToModel = (user: ApiModels.User): Models.User => withModelMetadata(user);

export const userPatchModelToApi = (patch: Models.UserPatch): ApiModels.UserPatch => patch;

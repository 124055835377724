import 'twin.macro';
import 'styled-components/macro';
import { Card } from './definitions/questions/Card';
import { useContext } from 'react';
import { RecorderContext } from './api/RecorderContext';
import { StyledButton } from './shared/Input';

function Home() {
    const ctx = useContext(RecorderContext);

    return (
        <Card fullWidth={true}>
            <div tw="p-4">
                {ctx.loggedIn ? (
                    <div>
                        Hello {ctx.api.token}
                        <StyledButton onClick={() => ctx.logout()} tw="block">
                            Log Out
                        </StyledButton>
                    </div>
                ) : (
                    <div>
                        You should log in!
                        <StyledButton onClick={() => ctx.login()} tw="block">
                            Log In
                        </StyledButton>
                    </div>
                )}
            </div>
        </Card>
    );
}
export default Home;

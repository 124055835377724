import tw from 'twin.macro';
import 'styled-components/macro';

import { useState } from 'react';
import { AddAnswerForm } from './AddAnswerForm';
import { AddAnswerPill, AnswerPill } from './AnswerPill';

import styled from '@emotion/styled';
import { UiAnswer } from '../models';

const StyledContainer = styled.div([
    tw`px-2 py-3 rounded m-auto bg-slate-50 `,
    ({ error }: { error: boolean }) => (error ? tw`border-red-500 border bg-red-50 text-red-900` : ''),
]);

export const sortAnswers = (answerA: UiAnswer, answerB: UiAnswer) => (answerA.label ?? '').localeCompare(answerB.label ?? '');

interface AnswerListProps {
    answers: UiAnswer[];
    onAnswersUpdate: (answers: UiAnswer[]) => void;
    editable: boolean;
    error?: any;
    type: 'number' | 'text';
}
export function AnswerList({ answers, onAnswersUpdate, editable, error, type }: AnswerListProps) {
    const sortedAnswers = [...answers].sort(sortAnswers);

    const addAnswer = (answer: UiAnswer) => {
        const newAnswers = [...sortedAnswers, answer].sort(sortAnswers);
        onAnswersUpdate(newAnswers);
        // setShowAddForm(false);
    };
    const deleteAnswer = (answerId: string) => {
        const newAnswers = sortedAnswers.filter((a) => a.id !== answerId);
        onAnswersUpdate(newAnswers);
    };

    const [showAddForm, setShowAddForm] = useState<boolean>(false);

    return (
        <StyledContainer error={error}>
            <div tw="flex flex-wrap">
                {sortedAnswers.map((answer) => (
                    <AnswerPill answer={answer} key={answer.id} onClick={() => deleteAnswer(answer.id)} editable={editable} />
                ))}
                {editable ? <AddAnswerPill isAdding={showAddForm} setIsAdding={(isAdding) => setShowAddForm(isAdding)} /> : ''}
            </div>
            {showAddForm && editable ? (
                <div tw="mt-2">
                    <AddAnswerForm onAdd={(answer) => addAnswer(answer)} type={type} />
                </div>
            ) : (
                ''
            )}
        </StyledContainer>
    );
}

import 'twin.macro';
import 'styled-components/macro';
import { useMemo, useState } from 'react';
import { FaPencilAlt } from 'react-icons/fa';

const availableTimezones = [
    'Europe/London',
    'America/New_York',
    'America/Chicago',
    'America/Denver',
    'America/Los_Angeles',
    'Europe/Paris',
    'Europe/Berlin',
    'Etc/Utc',
];

export interface TimezoneControlProps {
    patchTz: string | undefined;
    initialTz: string;
    onTzChange: (timezone: string) => void;
}
function TimezoneControl({ initialTz, patchTz, onTzChange }: TimezoneControlProps) {
    const [editing, setEditing] = useState(false);

    const tzList = useMemo(() => {
        return [initialTz, ...availableTimezones.filter((tz) => tz !== initialTz)];
    }, [initialTz]);

    return (
        <div>
            <div aria-label="timezone">
                Timezone:&nbsp;
                {editing ? (
                    <div tw="inline-flex items-center">
                        <select
                            tw="border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            defaultValue={initialTz}
                            onChange={(e) => onTzChange(e.target.value)}
                            aria-label="timezone select"
                        >
                            {tzList.map((tz) => (
                                <option key={tz} value={tz}>
                                    {tz}
                                </option>
                            ))}
                        </select>
                        <FaPencilAlt
                            tw="ml-2 text-gray-400 hover:text-gray-500 cursor-pointer"
                            onClick={() => setEditing(false)}
                            aria-label="edit timezone icon"
                        />
                    </div>
                ) : (
                    <div tw="inline-flex items-center">
                        {patchTz || initialTz}
                        <FaPencilAlt
                            tw="ml-2 text-gray-400 cursor-pointer"
                            onClick={() => setEditing(true)}
                            aria-label="edit timezone icon"
                        />
                    </div>
                )}
            </div>
        </div>
    );
}
export default TimezoneControl;

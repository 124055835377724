import { Models } from '@rckrds/recorder-models';
import { useState } from 'react';

export enum EditLifecycleState {
    Initial,
    Editing,
    Touched,
    Saving,
}

export interface EditState {
    state: EditLifecycleState;
    patch: Models.QuestionDefinitionPatch;
}
export const useEditState = () => {
    const [editState, setEditState] = useState<EditState>({
        state: EditLifecycleState.Initial,
        patch: {},
    });

    const editable = editState.state === EditLifecycleState.Editing || editState.state === EditLifecycleState.Touched;

    const startEditing = () => {
        setEditState({
            state: EditLifecycleState.Editing,
            patch: {},
        });
    };
    const startSaving = () => {
        setEditState({
            state: EditLifecycleState.Saving,
            patch: {},
        });
    };
    const quitEditing = () => {
        setEditState({
            state: EditLifecycleState.Initial,
            patch: {},
        });
    };
    const touchEditing = () => {
        setEditState({
            state: EditLifecycleState.Touched,
            patch: editState.patch,
        });
    };

    return { state: editState.state, editState, setEditState, editable, startEditing, quitEditing, touchEditing, startSaving };
};

import 'twin.macro';
import 'styled-components/macro';
import { useContext, useEffect, useState } from 'react';
import { RecorderContext } from '../api/RecorderContext';
import { Card } from '../definitions/questions/Card';
import AnswerHistoryChart from './AnswerHistoryChart';
import QuestionSelector from './QuestionSelector';
import { Models } from '@rckrds/recorder-models';

function DataHome() {
    const ctx = useContext(RecorderContext);

    const [questions, setQuestions] = useState<Models.EnrichedQuestionDefinition[]>([]);
    // load question definitions on page load
    useEffect(() => {
        if (ctx.api.ready) {
            ctx.recorder.getQuestionDefinitions().then(setQuestions);
        }
    }, [ctx, setQuestions]);

    const [selectedQuestion, setSelectedQuestion] = useState<Models.EnrichedQuestionDefinition | null>(null);
    const [answerData, setAnswerData] = useState<Models.AnswerDatum[]>([]);
    // load answer data on question selection
    useEffect(() => {
        if (selectedQuestion) {
            ctx.recorder
                .getAnswerData({
                    questionDefinitionIds: [selectedQuestion.id],
                })
                .then(setAnswerData);
        } else {
            setAnswerData([]);
        }
    }, [selectedQuestion, ctx, setAnswerData, selectedQuestion]);

    // choose first question on pgae load (definition load)
    useEffect(() => {
        if (!selectedQuestion && questions.length > 0) {
            setSelectedQuestion(questions[0]);
        }
    }, [questions, selectedQuestion, setSelectedQuestion]);

    return (
        <div tw="flex flex-wrap">
            <Card style={{ width: 200, flexGrow: '0' }}>
                <QuestionSelector
                    questions={questions}
                    onSelected={(question) => setSelectedQuestion(question)}
                    selectedQuestion={selectedQuestion}
                />
                {answerData.length > 0 && <AnswerHistoryChart answerData={answerData} questionDefinition={selectedQuestion!} />}
            </Card>
        </div>
    );
}
export default DataHome;

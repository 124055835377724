import { QuestionType } from '@rckrds/recorder-models/models/question';

export const questionTypeToString = (t: QuestionType): string =>
    ({
        0: 'Number',
        1: 'Multichoice',
    }[t]);

interface InputProps {
    type: 'number' | 'text';
}
export const questionTypeToInputProps = (t: QuestionType): InputProps =>
    ((
        {
            0: {
                type: 'number',
                step: '0.01',
            },
            1: {
                type: 'text',
            },
        } as any
    )[t]);

export const parseNumber = (val: string): Number => {
    if (Number.isNaN(Number(val))) {
        console.warn('Invalid num val', val);
        return 0;
    }
    return Number(val);
};

import tw from 'twin.macro';
import styled from '@emotion/styled';

export const StyledInput = styled.input([
    tw`
text-sm rounded-lg border

block
w-full

p-2.5
`,
    ({ error }: { error: any }) =>
        error
            ? tw`
bg-red-50 border-red-500 text-red-900 placeholder-red-700
focus:ring-red-500 focus:border-red-500
`
            : tw`
bg-gray-50 border-gray-300 text-gray-900
focus:ring-blue-500 focus:border-blue-500
focus:bg-white
`,
]);

export const StyledCheckbox = styled.input([
    tw`
border border-gray-300
rounded
    text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600
`,
]);

export const StyledButton = styled.button([
    tw`
    text-white
    bg-blue-700
    hover:bg-blue-800
    focus:ring-4 focus:outline-none focus:ring-blue-300
    font-medium rounded-lg text-sm
    px-4 py-2
`,
]);

export const Label = tw.span`
text-gray-500
mr-1
`;

import tw from 'twin.macro';
import 'styled-components/macro';

import { useEffect } from 'react';

import styled from '@emotion/styled';
import { StyledCheckbox, StyledInput } from '../../shared/Input';
import { FieldValues, useForm } from 'react-hook-form';
import { EditLifecycleState } from './edit';
import { questionTypeToString } from './format';
import React from 'react';
import { Models } from '@rckrds/recorder-models';

const Label = tw.span`
text-gray-500
mr-1
`;

const StyledFormInput = styled(StyledInput)([tw`flex-grow flex-shrink p-2`]);

const StyledFormContainer = styled.form([
    tw`grid gap-y-2 items-center`,
    {
        gridTemplateColumns: 'minmax(5em, min-content) 1fr',
    },
]);

interface QuestionCardFormProps {
    formName: string;
    question: Models.EnrichedQuestionDefinition | Models.QuestionDefinitionInput;
    onSubmit: (data: FieldValues) => void;

    editable: boolean;
    editState: EditLifecycleState;
    touchEditing: () => void;
}
export function QuestionCardForm({ formName, question, onSubmit, editable, editState, touchEditing }: QuestionCardFormProps) {
    const { register, handleSubmit, formState } = useForm({
        defaultValues: {
            label: question.label,
            cron: question.cron,
            command: question.command,
            type: question.type.toString(),
            enabled: question.enabled,
            allowMultipleAnswers: question.allowMultipleAnswers,
            allowDuplicateAnswers: question.allowDuplicateAnswers,
        },
    });

    useEffect(() => {
        if (editState === EditLifecycleState.Editing && formState.isDirty) {
            touchEditing();
        }
    }, [formState, editState, touchEditing]);

    return (
        <StyledFormContainer onSubmit={handleSubmit((data) => onSubmit(data))} name={formName} id={formName} role="form">
            <React.Fragment>
                <Label>Enabled</Label>
                {editable ? (
                    <div tw="inline">
                        <StyledCheckbox type="checkbox" placeholder="Enabled" {...register('enabled')} aria-label="Enabled" />
                    </div>
                ) : (
                    <div>{question.enabled ? 'Yes' : 'No'}</div>
                )}
            </React.Fragment>
            <div tw="mb-3 col-span-2">
                {editable ? (
                    <StyledFormInput
                        placeholder="Label"
                        {...register('label', { required: true })}
                        error={formState.errors?.label}
                        aria-label="Label"
                    />
                ) : (
                    <div>{question.label}</div>
                )}
            </div>
            <React.Fragment>
                <Label>Type</Label>
                {editable ? (
                    <select {...register('type', { required: true })} aria-label="Type">
                        <option value="0">Number</option>
                        <option value="1">Multichoice</option>
                    </select>
                ) : (
                    <div>{questionTypeToString(question.type)}</div>
                )}
            </React.Fragment>
            <React.Fragment>
                <Label>Cron</Label>
                {editable ? (
                    <StyledFormInput
                        placeholder="Cron"
                        {...register('cron', {
                            required: true,
                            pattern: {
                                value: /^(((\d+,)+\d+|(\d+(\/|-)\d+)|\d+|\*) ?){5,7}$/i,
                                message: 'invalid cron pattern',
                            },
                        })}
                        error={formState.errors?.cron}
                        aria-label="Cron"
                    />
                ) : (
                    <div>{question.cron}</div>
                )}
            </React.Fragment>
            <React.Fragment>
                <Label>Command (optional)</Label>
                {editable ? (
                    <StyledFormInput
                        placeholder="Command"
                        {...register('command', {
                            required: false,
                        })}
                        error={formState.errors?.command}
                        aria-label="Command"
                    />
                ) : (
                    <div>{question.command}</div>
                )}
            </React.Fragment>
            <React.Fragment>
                <Label>Allow Multiple Answers</Label>
                {editable ? (
                    <div tw="inline">
                        <StyledCheckbox
                            type="checkbox"
                            placeholder="Allow Multiple Answers"
                            {...register('allowMultipleAnswers')}
                            aria-label="Allow Multiple Answers"
                        />
                    </div>
                ) : (
                    <div>{question.allowMultipleAnswers ? 'Yes' : 'No'}</div>
                )}
            </React.Fragment>
            <React.Fragment>
                <Label>Allow Duplicate Answers</Label>
                {editable ? (
                    <div tw="inline">
                        <StyledCheckbox
                            type="checkbox"
                            placeholder="Allow Duplicate Answers"
                            {...register('allowDuplicateAnswers')}
                            aria-label="Allow Duplicate Answers"
                        />
                    </div>
                ) : (
                    <div>{question.allowDuplicateAnswers ? 'Yes' : 'No'}</div>
                )}
            </React.Fragment>
            <input type="submit" style={{ display: 'none' }} aria-label="Submit" />
        </StyledFormContainer>
    );
}

import 'twin.macro';
import 'styled-components/macro';
import { useMemo } from 'react';
import { Models } from '@rckrds/recorder-models';

export interface QuestionSelectorProps {
    questions: Models.EnrichedQuestionDefinition[];
    selectedQuestion: Models.EnrichedQuestionDefinition | null;
    onSelected: (question: Models.EnrichedQuestionDefinition | null) => void;
}

function QuestionSelector(props: QuestionSelectorProps) {
    const questionsMap = useMemo(() => {
        const map = new Map<string, Models.EnrichedQuestionDefinition>();
        props.questions.forEach((q) => map.set(q.id, q));
        return map;
    }, [props.questions]);

    const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => props.onSelected(questionsMap.get(event.target.value) ?? null);

    return (
        <div tw="flex">
            <select tw="w-full" onChange={onChange} role="select">
                <option>Choose question</option>
                {props.questions.map((q) => (
                    <option key={q.id} value={q.id} label={q.label} selected={props.selectedQuestion?.id === q.id}>
                        {q.label} ({q.key})
                    </option>
                ))}
            </select>
        </div>
    );
}

export default QuestionSelector;

import tw from 'twin.macro';
import 'styled-components/macro';

import { FaPlus, FaRegTimesCircle, FaTimesCircle } from 'react-icons/fa';
import styled from '@emotion/styled';
import { useState } from 'react';
import { UiAnswer } from '../models';

enum StyledPillType {
    Normal,
    Delete,
    Add,
    AddHover,
}
interface HoverProps {
    pillType: StyledPillType;
}

const PillFiller = () => <FaTimesCircle style={{ visibility: 'hidden' }} />;

// <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
// Button
// </button>
const StyledPill = styled.button([
    `min-width: 120px`,
    tw`
    inline-flex items-center justify-between

    border-2 border-solid
    bg-white

    text-xs
    py-1 px-3 rounded-full
    m-0.5
    `,
    (p: HoverProps) =>
        ((
            {
                [StyledPillType.Delete]: tw`bg-red-500 text-white`,
                [StyledPillType.Add]: tw`bg-green-500 text-white`,
                [StyledPillType.AddHover]: tw`bg-green-600 text-white`,
            } as any
        )[p.pillType] ?? ''),
]);

const StyledPillButton = styled.div(tw`inline ml-1`);

interface AnswerPillProps {
    answer: UiAnswer;
    onClick: React.MouseEventHandler;
    editable: boolean;
}
export function AnswerPill({ answer, onClick, editable }: AnswerPillProps) {
    const [hover, setHover] = useState<boolean>(false);

    return (
        <StyledPill pillType={hover ? StyledPillType.Delete : StyledPillType.Normal} aria-label="Answer">
            <PillFiller />
            {answer.label} ({answer.value})
            {editable ? (
                <StyledPillButton
                    as={FaRegTimesCircle}
                    onClick={onClick}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    aria-label="Remove"
                />
            ) : (
                <PillFiller />
            )}
        </StyledPill>
    );
}

interface AddAnswerPillProps {
    isAdding: boolean;
    setIsAdding: (isAdding: boolean) => void;
}
export function AddAnswerPill({ isAdding, setIsAdding }: AddAnswerPillProps) {
    const [hover, setHover] = useState<boolean>(false);

    const pillType = hover ? StyledPillType.AddHover : isAdding ? StyledPillType.Add : StyledPillType.Normal;

    return (
        <StyledPill
            pillType={pillType}
            onClick={() => setIsAdding(!isAdding)}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            aria-label="Add Answer"
        >
            <PillFiller />
            Add
            <StyledPillButton as={FaPlus} />
        </StyledPill>
    );
}

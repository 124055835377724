import tw from 'twin.macro';
import 'styled-components/macro';
import styled from '@emotion/styled';

import { EditLifecycleState } from './edit';

interface CardProps {
    editLifecycle?: EditLifecycleState;
    fullWidth?: boolean;
}
export const Card = styled.div([
    tw`rounded-md m-2 flex-grow flex-shrink basis-0 border border-solid border-gray-100 bg-white`,
    ({ fullWidth }: CardProps) => (fullWidth ? tw`w-full` : { minWidth: '500px' }),
    ({ editLifecycle }: CardProps) =>
        editLifecycle
            ? {
                  [EditLifecycleState.Initial]: tw``,
                  [EditLifecycleState.Editing]: tw`border-blue-500`,
                  [EditLifecycleState.Touched]: tw`border-blue-500 bg-rose-50`,
                  [EditLifecycleState.Saving]: tw`border-blue-500 bg-rose-50`,
              }[editLifecycle] ?? ''
            : '',
]);
export const CardBody = tw.div`
p-4
border-solid border-0 border-t border-gray-400
`;

export const CardHeader = tw.div`
mx-4 mb-2 mt-2 text-gray-900 font-bold text-2xl tracking-tight flex items-center justify-between
`;

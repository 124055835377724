import { Models } from '@rckrds/recorder-models';
import { add, compareAsc, format, isAfter, parse, set, startOfDay } from 'date-fns';

export const middleOfDay = (date: Date) =>
    set(date, {
        hours: 12,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
    });

const uniq = (vals: number[]): number[] => Array.from(new Set(vals));
const sort = (vals: number[]): number[] => [...vals].sort((a, b) => a - b);

const extractSortedVals = (vals: any[]) => sort(uniq(vals.filter((v) => typeof v === 'number')));

const extractDaysFromData = (data: Models.AnswerDatum[]): Date[] => {
    const sortedDays: Date[] = [];
    const sortedDates = data.map((d) => d.createdAt).sort(compareAsc);
    let date = startOfDay(sortedDates[0]);
    const end = sortedDates[sortedDates.length - 1];
    let cbCount = 0;
    while (!isAfter(date, end) && cbCount < 100) {
        sortedDays.push(date);
        date = add(date, {
            days: 1,
        });
        cbCount++;
    }
    return sortedDays;
};

const extractDailyAvgsFromData = (data: Models.AnswerDatum[]): { x: Date; y: number }[] => {
    const dateKeyFormat = 'MM/dd/yyyy';

    const dailyValues: { [k: string]: number[] } = {};
    const avg = (x: number[]) => x.reduce((a, b) => a + b, 0) / x.length;
    data.forEach((d) => {
        const k = format(d.createdAt, dateKeyFormat);
        if (!dailyValues[k]) {
            dailyValues[k] = [];
        }
        dailyValues[k].push(d.value as number);
    });
    return Object.keys(dailyValues)
        .map((dateStr) => parse(dateStr, dateKeyFormat, new Date()))
        .map((d) => middleOfDay(d))
        .sort(compareAsc)
        .map((d) => ({
            x: d,
            y: avg(dailyValues[format(d, dateKeyFormat)]),
        }));
};

interface MungedAnswerData {
    domain: {
        y: [number, number];
    };

    timeframe: {
        dayStarts: Date[];
    };

    values: {
        numericalData: Models.AnswerDatum[];
        sortedValues: number[];
        sortedValueOptions: number[];
        dailyAvgs: { x: Date; y: number }[];
    };
}
export const mungeAnswerData = (data: Models.AnswerDatum[], questionDefinition: Models.EnrichedQuestionDefinition): MungedAnswerData => {
    // values
    const numericalData: Models.AnswerDatum[] = data.filter((d) => typeof d.value === 'number');
    const sortedValues: number[] = extractSortedVals(data.map((d) => d.value));

    // max/min bounds
    const sortedValueOptions: number[] = extractSortedVals(questionDefinition.answerDefinitions.map((d) => d.value));
    const yDomain = [
        Math.min(sortedValues[0], sortedValueOptions[0]),
        Math.max(sortedValues[sortedValues.length - 1], sortedValueOptions[sortedValueOptions.length - 1]),
    ] as [number, number];

    return {
        domain: {
            y: yDomain,
        },
        timeframe: {
            dayStarts: extractDaysFromData(numericalData).map(startOfDay),
        },
        values: {
            numericalData,
            sortedValues,
            sortedValueOptions,
            dailyAvgs: extractDailyAvgsFromData(numericalData),
        },
    };
};

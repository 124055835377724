import 'twin.macro';
import 'styled-components/macro';

import { useContext, useEffect, useState } from 'react';
import { AnswerList } from '../answers/AnswersList';

import { FaRegEdit, FaCheck, FaSpinner, FaRegTimesCircle } from 'react-icons/fa';
import type { FieldValues } from 'react-hook-form';

import { EditLifecycleState, useEditState } from './edit';
import { QuestionCardForm } from './QuestionCardForm';
import { Label } from '../../shared/Input';
import { Card, CardBody, CardHeader } from './Card';
import { RecorderContext } from '../../api/RecorderContext';
import { Models } from '@rckrds/recorder-models';
import { UiAnswer } from '../models';
import {
    transformQuestionDefinitionToUiAnswers,
    transformUiToQuestionDefinitionInput,
    transformUiToQuestionDefinitionPatch,
} from './transforms';
import { questionTypeToInputProps } from './format';

const getHeaderBtns = (state: EditLifecycleState) => ({
    showEditBtn: state === EditLifecycleState.Initial,
    showCancelBtn: state === EditLifecycleState.Editing || state === EditLifecycleState.Touched,
    showSubmitBtn: state === EditLifecycleState.Touched,
    showSavingSpinner: state === EditLifecycleState.Saving,
});

interface QuestionCardProps {
    question: Models.EnrichedQuestionDefinition | Models.QuestionDefinitionInput;
    updateQuestion?: (question: Models.EnrichedQuestionDefinition) => void;
    publishDraft?: (question: Models.EnrichedQuestionDefinition) => void;
    cancelDraft?: (question: Models.QuestionDefinitionInput) => void;
}
export function QuestionCard({ question, updateQuestion, publishDraft, cancelDraft }: QuestionCardProps) {
    const [answers, setAnswers] = useState<UiAnswer[]>([]);
    const [answersError, setAnswersError] = useState<string | null>(null);
    const [answersTouched, setAnswersTouched] = useState<boolean>(false);
    const edit = useEditState();

    const isDraft = !('id' in question);

    const ctx = useContext(RecorderContext);

    // when done editing, reset answers section
    useEffect(() => {
        if (edit.editState.state === EditLifecycleState.Initial) {
            setAnswersError(null);
            setAnswers(transformQuestionDefinitionToUiAnswers(question));
            setAnswersTouched(false);
            if (isDraft) {
                edit.startEditing();
            }
        }
        // eslint-disable-next-line
    }, [question, edit.editState]);

    // on answers change, persist and touch form
    const onAnswersUpdate = (answers: UiAnswer[]) => {
        setAnswers(answers);
        calculateAnswersError(answers);
        edit.touchEditing();
        setAnswersTouched(true);
    };

    const calculateAnswersError = (answers: UiAnswer[]) => {
        const isAnswersValid = !!answers.length;
        if (isAnswersValid) {
            setAnswersError(null);
        } else {
            setAnswersError('Answers must be present');
        }
        return isAnswersValid;
    };

    // update definition, quit form, store patched definition
    const mutateQuestion = async (data: FieldValues) => {
        const isAnswersValid = calculateAnswersError(answers);
        if (!isAnswersValid) {
            return;
        }

        edit.startSaving();
        if (isDraft) {
            const def = transformUiToQuestionDefinitionInput(question, data, answers);
            const res = await ctx.recorder.createQuestionDefinition(def);
            edit.quitEditing();
            if (publishDraft) {
                publishDraft(res);
            }
        } else {
            const patch = transformUiToQuestionDefinitionPatch(data, answersTouched ? answers : null);
            const res = await ctx.recorder.patchQuestionDefinition(question.id, patch);
            edit.quitEditing();
            if (updateQuestion) {
                updateQuestion(res);
            }
        }
    };

    function terminateEditing() {
        edit.quitEditing();
        if (isDraft && cancelDraft) {
            cancelDraft(question);
        }
    }

    const formName = `question-${'id' in question ? question.id : 'draft'}`;
    const headerBtns = getHeaderBtns(edit.state);

    return (
        <Card editLifecycle={edit.state}>
            <CardHeader role="heading">
                {question.key}
                <div tw="flex items-center justify-between">
                    {headerBtns.showEditBtn ? (
                        <button onClick={() => edit.startEditing()} aria-label="Edit">
                            <FaRegEdit />
                        </button>
                    ) : (
                        ''
                    )}
                    {headerBtns.showCancelBtn ? (
                        <button onClick={() => terminateEditing()} aria-label="Cancel">
                            <FaRegTimesCircle />
                        </button>
                    ) : (
                        ''
                    )}
                    {headerBtns.showSubmitBtn ? (
                        <button type="submit" form={formName} tw="ml-2" aria-label="Update">
                            <FaCheck />
                        </button>
                    ) : (
                        ''
                    )}
                    {headerBtns.showSavingSpinner ? (
                        <button>
                            <FaSpinner aria-label="Saving" />
                        </button>
                    ) : (
                        ''
                    )}
                </div>
            </CardHeader>
            <CardBody>
                <QuestionCardForm
                    question={question}
                    formName={formName}
                    onSubmit={(data) => mutateQuestion(data)}
                    editable={edit.editable}
                    editState={edit.state}
                    touchEditing={edit.touchEditing}
                />
                <div tw="mt-3">
                    <Label>Answers</Label>
                    <AnswerList
                        onAnswersUpdate={onAnswersUpdate}
                        answers={answers}
                        editable={edit.editable}
                        error={answersError}
                        {...questionTypeToInputProps(question.type)}
                    />
                </div>
            </CardBody>
        </Card>
    );
}

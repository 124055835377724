import 'twin.macro';
import 'styled-components/macro';
import { useContext, useEffect, useState } from 'react';
import TimezoneControl from './TimezoneControl';
import { RecorderContext } from '../api/RecorderContext';
import { StyledButton } from '../shared/Input';
import { Models } from '@rckrds/recorder-models';

function Settings() {
    // original user
    const [user, setUser] = useState<Models.User | null>(null);
    const loadUser = () => ctx.recorder.getUser().then(setUser);
    useEffect(() => {
        loadUser();
    }, []);

    // local changes
    const [userPatch, setUserPatch] = useState<Models.UserPatch>({});
    const patchUserPatch = (patchUpdate: Models.UserPatch) => setUserPatch({ ...userPatch, ...patchUpdate });

    const ctx = useContext(RecorderContext);
    const submitPatch = () =>
        ctx.recorder.patchUser(userPatch).then((user: Models.User) => {
            setUserPatch({});
            setUser(user);
        });

    return (
        <div>
            <h3>Settings</h3>
            {user ? (
                <div>
                    <TimezoneControl
                        initialTz={user.timezone}
                        patchTz={userPatch.timezone}
                        onTzChange={(timezone) => patchUserPatch({ timezone })}
                    />
                </div>
            ) : (
                'Loading...'
            )}
            {Object.keys(userPatch).length > 0 && (
                <StyledButton onClick={submitPatch} tw="mt-4" aria-label="save settings">
                    Save
                </StyledButton>
            )}
        </div>
    );
}
export default Settings;

var Handlebars = require("../lib/handlebars.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div "
    + ((stack1 = lookupProperty(helpers,"class").call(alias1,"dev-tools",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + "><div "
    + ((stack1 = lookupProperty(helpers,"class").call(alias1,"nav-bar-container",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + "><div "
    + ((stack1 = lookupProperty(helpers,"class").call(alias1,"nav-bar",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + "></div><div class=\"eruda-bottom-bar\"></div></div><div "
    + ((stack1 = lookupProperty(helpers,"class").call(alias1,"tools",{"name":"class","hash":{},"data":data,})) != null ? stack1 : "")
    + "></div><div class=\"eruda-notification\"></div></div>";
},"useData":true});
import tw from 'twin.macro';
import 'styled-components/macro';

import styled from '@emotion/styled';
import { FieldValues, useForm } from 'react-hook-form';
import { StyledButton, StyledInput } from '../../shared/Input';

import { FaPlus } from 'react-icons/fa';
import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { UiAnswer } from '../models';

const StyledAnswerInput = styled(StyledInput)([tw`flex-grow flex-shrink text-xs`]);

interface AddAnswerFormProps {
    onAdd: (answer: UiAnswer) => void;
    style?: any;
    type: 'number' | 'text';
}
export function AddAnswerForm({ onAdd, style, type }: AddAnswerFormProps) {
    const { register, handleSubmit, formState, reset } = useForm();

    useEffect(() => {
        if (formState.isSubmitSuccessful) {
            reset({
                label: null,
                value: null,
            });
        }
    }, [formState, reset]);

    const transformToAnswer = (vals: FieldValues): UiAnswer => ({
        id: uuidv4(),
        label: vals.label,
        value: vals.value,
    });

    const inputProps = {} as any;
    if (type === 'number') {
        inputProps.step = 'any';
    }

    return (
        <div>
            <form onSubmit={handleSubmit((data) => onAdd(transformToAnswer(data)))} style={style}>
                <div tw="flex flex-row space-x-4">
                    <StyledAnswerInput
                        aria-label="Answer Label"
                        placeholder="Label"
                        {...register('label', { required: true })}
                        error={formState.errors?.label}
                    />
                    <StyledAnswerInput
                        aria-label="Answer Value"
                        placeholder="Value"
                        type={type}
                        {...inputProps}
                        {...register('value', { required: true })}
                        error={formState.errors?.value}
                    />
                    <StyledButton type="submit" tw="flex-grow-0 flex-shrink-0 text-xs" aria-label="Add">
                        <FaPlus />
                    </StyledButton>
                </div>
            </form>
        </div>
    );
}

import { Component, ReactNode } from 'react';

interface ErrorBoundaryProps {
    children: ReactNode[] | ReactNode;
}

class ErrorBoundary extends Component {
    state: {
        error: Error | null;
    } = {
        error: null,
    };

    constructor(public props: ErrorBoundaryProps) {
        super(props);
    }

    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI.
        return {
            error,
        };
    }

    componentDidCatch(error: any, errorInfo: any) {
        alert('error');
        // You can also log the error to an error reporting service like AppSignal
        // logErrorToMyService(error, errorInfo);
    }

    render() {
        const { error } = this.state;

        if (error) {
            // You can render any custom fallback UI
            return (
                <div>
                    <p>Something went wrong 😭</p>

                    {error.message && <span>Here's the error: {error.message}</span>}
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;

import { Models, Service } from '@rckrds/recorder-models';
import * as transforms from '@rckrds/recorder-models/transforms';

export interface RecorderOpts {
    apiUrl: string | null;
    token: string | null;
}

export class Recorder {
    private opts: RecorderOpts;
    constructor(opts?: RecorderOpts) {
        this.opts = opts || {
            apiUrl: null,
            token: null,
        };
    }

    private validateOpts() {
        if (!this.opts.apiUrl) {
            throw new Error('no api base');
        }

        if (!this.opts.token) {
            throw new Error('no token');
        }
    }

    private async token() {
        return this.opts.token;
    }

    async createQuestionDefinition(definition: Models.QuestionDefinitionInput): Promise<Models.EnrichedQuestionDefinition> {
        this.validateOpts();

        const data = await this.makeRequest<Service.CreateQuestionDefinitionRequest, Service.CreateQuestionDefinitionResponse>(
            'questions/definitions',
            {
                method: 'POST',
                body: {
                    question: transforms.questionDefinitionInputModelToApi(definition),
                },
            }
        );
        if (!data?.question) {
            throw new Error('invalid result');
        }
        return transforms.enrichedQuestionDefinitionApiToModel(data.question);
    }

    async patchQuestionDefinition(id: string, definition: Models.QuestionDefinitionPatch): Promise<Models.EnrichedQuestionDefinition> {
        this.validateOpts();

        const data = await this.makeRequest<Service.PatchQuestionDefinitionRequest, Service.PatchQuestionDefinitionResponse>(
            `questions/definitions/${id}`,
            {
                method: 'PUT',
                body: {
                    question: transforms.questionDefinitionPatchModelToApi(definition),
                },
            }
        );
        if (!data?.question) {
            throw new Error('invalid result');
        }
        return transforms.enrichedQuestionDefinitionApiToModel(data.question);
    }

    async getQuestionDefinitions(): Promise<Models.EnrichedQuestionDefinition[]> {
        const data = await this.makeRequest<Service.GetQuestionDefinitionsRequest, Service.GetQuestionDefinitionsResponse>(
            'questions/definitions',
            {}
        );
        if (!data?.questions?.length) {
            throw new Error('no questions');
        }
        return data.questions.map(transforms.enrichedQuestionDefinitionApiToModel);
    }

    async getAnswerData(filter: Models.AnswerDataFilter): Promise<Models.AnswerDatum[]> {
        this.validateOpts();

        const apiFilter = transforms.answerDataFilterModelToApi(filter);
        const data = await this.makeRequest<Service.GetAnswersDataRequest, Service.GetAnswersDataResponse>('data/answers', {
            method: 'POST',
            body: {
                filter: apiFilter,
            },
        });
        if (!data?.data?.length) {
            throw new Error('no data');
        }
        return data.data.map(transforms.answerDatumApiToModel);
    }

    // get current user from /user/me
    async getUser(): Promise<Models.User> {
        this.validateOpts();

        const data = await this.makeRequest<Service.GetMeRequest, Service.GetMeResponse>('users/me', {});
        if (!data?.user) {
            throw new Error('invalid result');
        }
        return transforms.userApiToModel(data.user);
    }

    // patch current user at PATCH /user/me
    async patchUser(user: Partial<Models.UserPatch>): Promise<Models.User> {
        this.validateOpts();

        const data = await this.makeRequest<Service.PatchMeRequest, Service.PatchMeResponse>('users/me', {
            method: 'PATCH',
            body: {
                user: transforms.userPatchModelToApi(user),
            },
        });
        if (!data?.user) {
            throw new Error('invalid result');
        }
        return transforms.userApiToModel(data.user);
    }

    private async makeRequest<CustomRequest, CustomResponse>(
        path: string,
        opts: Omit<RequestInit, 'body'> & { body?: CustomRequest }
    ): Promise<CustomResponse> {
        const url = this.makeUrl(path);
        const jsonBody = opts?.body && typeof opts.body === 'object';
        const fetchOpts = {
            ...opts,
            body: jsonBody ? JSON.stringify(opts.body) : opts.body,
            headers: {
                'content-type': jsonBody ? 'application/json' : (opts.headers as any)?.['content-type'],
                authorization: `Bearer ${await this.token()}`,
                ...(opts.headers || {}),
            },
        } as RequestInit;
        console.debug('makeRequest', url, opts, fetchOpts);

        const resp = await fetch(url, fetchOpts);
        const data = await resp.json();
        return data;
    }

    private makeUrl(path: string): string {
        return `${this.opts.apiUrl}/${path}`;
    }
}

import tw from 'twin.macro';
import 'styled-components/macro';
import { Routes, Route, NavLink } from 'react-router-dom';

import './App.css';
import Definitions from './definitions/Definitions';
import Home from './Home';
import DataHome from './data/DataHome';
import Settings from './settings/Settings';

const activeNavStyle = tw`block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white`;
const inactiveNavStyle = tw`
block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700`;

function App() {
    return (
        <div tw="h-full">
            <nav tw="bg-white border-gray-200 px-2 sm:px-4 py-2.5 rounded">
                <div tw="container flex flex-wrap items-center justify-between mx-auto">
                    <a tw="flex items-center">
                        <span tw="self-center text-xl font-semibold whitespace-nowrap">Recorder</span>
                    </a>
                    <div tw="hidden w-full md:block md:w-auto" id="navbar-default">
                        <ul tw="flex flex-col p-4 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                            <li>
                                <NavLink to="/" style={(props: any) => (props.isActive ? activeNavStyle : inactiveNavStyle)}>
                                    Home
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/questions" style={(props: any) => (props.isActive ? activeNavStyle : inactiveNavStyle)}>
                                    Questions
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/data" style={(props: any) => (props.isActive ? activeNavStyle : inactiveNavStyle)}>
                                    Data
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/settings" style={(props: any) => (props.isActive ? activeNavStyle : inactiveNavStyle)}>
                                    Settings
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <div tw="bg-neutral-100 h-full px-4 py-2">
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/questions" element={<Definitions />} />
                    <Route path="/data" element={<DataHome />} />
                    <Route path="/settings" element={<Settings />} />
                </Routes>
            </div>
        </div>
    );
}

export default App;

import { Models } from '@rckrds/recorder-models';
import type { FieldValues } from 'react-hook-form';
import { UiAnswer } from '../models';
import { parseNumber } from './format';

const transformUiToAnswerDefinitionInput = (answers: UiAnswer[], questionType: string | number) =>
    answers.map((answer: UiAnswer) => ({
        ...answer,
        value: questionType.toString() === '0' ? (parseNumber(answer.value) as any) : answer.value,
    }));

export const transformUiToQuestionDefinitionPatch = (
    data: FieldValues,
    answers: UiAnswer[] | null
): Partial<Models.QuestionDefinitionInput> => {
    const patch = {
        label: data.label,
        cron: data.cron,
        type: Number(data.type),
        enabled: Boolean(data.enabled),
        allowDuplicateAnswers: Boolean(data.allowDuplicateAnswers),
        allowMultipleAnswers: Boolean(data.allowMultipleAnswers),
    } as Models.QuestionDefinitionInput;
    if (data.command) {
        patch.command = data.command;
    }
    if (answers) {
        patch.answerDefinitions = transformUiToAnswerDefinitionInput(answers, data.type);
    }
    return patch;
};

export const transformUiToQuestionDefinitionInput = (
    base: Models.QuestionDefinitionInput,
    data: FieldValues,
    answers: UiAnswer[]
): Models.QuestionDefinitionInput => {
    return {
        key: base.key,
        label: data.label,
        cron: data.cron,
        type: Number(data.type),
        enabled: Boolean(data.enabled),
        allowDuplicateAnswers: Boolean(data.allowDuplicateAnswers),
        allowMultipleAnswers: Boolean(data.allowMultipleAnswers),
        answerDefinitions: transformUiToAnswerDefinitionInput(answers, data.type),
        command: data.command || null,
    };
};

export const transformQuestionDefinitionToUiAnswers = (
    question: Models.QuestionDefinition | Models.QuestionDefinitionInput
): UiAnswer[] => {
    const defs = question?.answerDefinitions ?? [];
    return defs.map((answer) => ({
        ...answer,
        id: (answer as any).id,
        value: answer.value.toString(),
    }));
};

import 'twin.macro';
import 'styled-components/macro';

import { Card } from './Card';
import { EditLifecycleState } from './edit';
import { FaPlus } from 'react-icons/fa';

export function AddQuestionCard({ onClick }: { onClick: () => void }) {
    return (
        <Card editLifecycle={EditLifecycleState.Initial}>
            <button
                tw="p-4 flex items-center justify-center w-full h-full text-6xl text-slate-100 hover:text-slate-300"
                onClick={onClick}
                aria-label="Add Question"
            >
                <FaPlus />
            </button>
        </Card>
    );
}

import { Models } from '@rckrds/recorder-models';
import { add, format } from 'date-fns';
import { useMemo } from 'react';
import { VictoryAxis, VictoryChart, VictoryLine, VictoryScatter, VictoryTheme } from 'victory';
import { mungeAnswerData } from './transforms';

export interface AnswerHistoryChartProps {
    answerData: Models.AnswerDatum[];
    questionDefinition: Models.EnrichedQuestionDefinition;
}

function last<T>(arr: T[]): T {
    return arr[arr.length - 1];
}

function AnswerHistoryChart({ answerData, questionDefinition }: AnswerHistoryChartProps) {
    const d = useMemo(() => mungeAnswerData(answerData, questionDefinition), [answerData, questionDefinition]);

    // x axis
    const xTickValues = [...d.timeframe.dayStarts, add(last(d.timeframe.dayStarts), { days: 1 })];
    const xTickFormat = (tick: Date) => format(tick, 'EE M/d');

    // y axis
    const yTickValues = d.values.sortedValueOptions;

    return (
        <div data-testid="answer-history-chart">
            <VictoryChart theme={VictoryTheme.material} padding={{ bottom: 80, right: 40, left: 40, top: 20 }} domain={d.domain}>
                <VictoryAxis dependentAxis tickValues={yTickValues} />
                <VictoryAxis
                    tickValues={xTickValues}
                    fixLabelOverlap
                    tickFormat={xTickFormat}
                    style={{
                        tickLabels: {
                            angle: 0,
                            padding: 10,
                        },
                    }}
                />
                <VictoryScatter data={d.values.numericalData} style={{ data: { fill: '#c43a31' } }} size={3} x="createdAt" y="value" />
                <VictoryScatter data={d.values.dailyAvgs} size={3} />
                <VictoryLine data={d.values.dailyAvgs} interpolation="natural" />
            </VictoryChart>
        </div>
    );
}
export default AnswerHistoryChart;

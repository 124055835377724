import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Recorder } from './Recorder';

const API_LOCAL_STORAGE_KEY = 'apiContext';
const botName = process.env.REACT_BOT_NAME || 'RickardsRecorderSandboxBot';
const botLink = `https://t.me/${botName}/?start=true`;

export interface RecorderProviderProps {
    children: JSX.Element[] | JSX.Element;
}

export interface ApiContext {
    token: string | null;
    apiUrl: string | null;
    ready: boolean;
}
interface ApiData {
    token: string | null;
    apiUrl: string | null;
}

export interface RecorderContextData {
    api: ApiContext;
    recorder: Recorder;
    logout: () => void;
    login: () => void;
    loggedIn: boolean;
}

const apiDataToContext = (data: ApiData): ApiContext => ({
    token: data.token,
    apiUrl: data.apiUrl,
    ready: !!data.token,
});

const defaultApiData = (): ApiData => ({
    apiUrl: process.env.REACT_APP_API_BASE!,
    token: null,
});

const readLocalStorageApiData = (): ApiData | null => {
    console.debug('Looking for API data in local storage');
    try {
        const item = localStorage.getItem(API_LOCAL_STORAGE_KEY);
        if (item) {
            const v = {
                ...defaultApiData(),
                ...JSON.parse(item),
            };
            return v;
        }
        return null;
    } catch (err) {
        console.error('Error getting api data from local storage', err);
        return null;
    }
};

export const RecorderContext = React.createContext<RecorderContextData>({
    api: apiDataToContext(defaultApiData()),
    recorder: new Recorder(),
    loggedIn: false,
    logout: () => null,
    login: () => null,
});

const getTokenFromSearchParams = (params: URLSearchParams): string | null => {
    if (!params.has('token')) {
        return null;
    }
    return params.get('token')!.trim() || null;
};

export function RecorderProvider(props: RecorderProviderProps) {
    const [apiData, setApiData] = useState<ApiData>((): ApiData => {
        const fromStorage = readLocalStorageApiData();
        if (fromStorage) {
            const d = fromStorage;
            console.debug('setApiData from localStorage', d);
            return d;
        } else {
            const d = defaultApiData();
            console.debug('setApiData to default', d);
            return d;
        }
    });

    // set token from url params
    const [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
        const token = getTokenFromSearchParams(searchParams);
        if (token && apiData.token !== token) {
            console.debug('setApiData from updated url param', token);
            setApiData({
                ...apiData,
                token,
            });
        }
    }, [searchParams, apiData]);

    // persist ctx data between sessions
    useEffect(() => {
        console.debug('apiData changed, updating localStorage:', apiData);
        localStorage.setItem(API_LOCAL_STORAGE_KEY, JSON.stringify(apiData));
    }, [apiData]);

    const ctxValue: RecorderContextData = React.useMemo(() => {
        const api = apiDataToContext(apiData);
        const loggedIn = api.ready;
        return {
            api,
            loggedIn,
            logout: () => {
                setSearchParams();
                setApiData(defaultApiData());
            },
            login: () => window.location.replace(botLink),
            recorder: new Recorder(apiData),
        };
    }, [apiData, setSearchParams]);

    return <RecorderContext.Provider value={ctxValue}>{props.children}</RecorderContext.Provider>;
}

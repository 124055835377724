import tw from 'twin.macro';
import 'styled-components/macro';

import { QuestionCard } from './QuestionCard';
import { useContext, useEffect, useState } from 'react';
import { uniqueNamesGenerator, adjectives, colors, animals } from 'unique-names-generator';

// eslint-disable-next-line
import 'styled-components/macro';
import { AddQuestionCard } from './AddQuestionCard';
import { RecorderContext } from '../../api/RecorderContext';
import { Models } from '@rckrds/recorder-models';

const StyledGrid = tw.div`flex flex-wrap`;
export function QuestionsGrid() {
    const [questions, setQuestions] = useState<Models.EnrichedQuestionDefinition[]>([]);

    const updateQuestion = (question: Models.EnrichedQuestionDefinition) => {
        const splitIdx = questions.findIndex((q) => 'id' in q && q.id === question.id);
        setQuestions([...questions.slice(0, splitIdx), question, ...questions.slice(splitIdx + 1)]);
    };

    const ctx = useContext(RecorderContext);
    useEffect(() => {
        if (ctx.api.ready) {
            ctx.recorder.getQuestionDefinitions().then(setQuestions);
        }
    }, [ctx]);

    const [draftQuestion, setDraftQuestion] = useState<Models.QuestionDefinitionInput | null>(null);

    const startDraftQuestion = () => {
        const newQuestion: Models.QuestionDefinitionInput = {
            label: '',
            key: uniqueNamesGenerator({
                dictionaries: [adjectives, colors, animals],
                separator: '-',
            }),
            cron: '',
            type: 0,
            allowMultipleAnswers: false,
            allowDuplicateAnswers: false,
            enabled: false,
            answerDefinitions: [],
            command: '',
        };
        setDraftQuestion(newQuestion);
    };
    const cancelDraft = (question: Models.QuestionDefinitionInput) => {
        setDraftQuestion(null);
    };
    const publishDraft = (question: Models.EnrichedQuestionDefinition) => {
        setDraftQuestion(null);
        setQuestions([question, ...questions.filter((question) => 'id' in question)]);
    };

    return (
        <StyledGrid>
            {questions.map((question) => (
                <QuestionCard key={question.key} question={question} updateQuestion={updateQuestion}></QuestionCard>
            ))}
            {draftQuestion ? (
                <QuestionCard question={draftQuestion} cancelDraft={cancelDraft} publishDraft={publishDraft}></QuestionCard>
            ) : (
                <AddQuestionCard onClick={startDraftQuestion} />
            )}
        </StyledGrid>
    );
}
